import styled, { css } from '@xstyled/styled-components'

interface MuxVideoWrapperProps {
  aspectRatio: string
}

const aspectRatioToPadding = (aspectRatio: string) => {
  if (/\d+:\d+/.test(aspectRatio) === false) {
    throw new Error(`"${aspectRatio}" is not a valid aspect ratio`)
  }
  const [w, h] = aspectRatio.split(':').map((value) => parseInt(value))
  return `${(h / w) * 100}%`
}

export const MuxVideoWrapper = styled.div<MuxVideoWrapperProps>`
  ${({ aspectRatio }) => css`
    position: relative;
    width: 100%;
    padding-bottom: ${aspectRatioToPadding(aspectRatio)};
  `}
`
