import React, { FC } from 'react'
import { GetStaticProps } from 'next'

import { client } from '@/lib/client'
import { HomePage } from '@/types/sanity'
import { PageProps } from '@/types/shared'
import { HomeView } from '@/views/HomeView'
import { Sentry } from '@/lib/sentry'

interface HomeProps extends PageProps {
  homePage?: HomePage | null
}

const Home: FC<HomeProps> = ({ homePage }) => {
  if (!homePage) {
    return <p>Sorry, there was an error</p>
  }
  return <HomeView homePage={homePage} />
}

/**
 * Initial Props
 */

export const getStaticProps: GetStaticProps<HomeProps> = async (ctx) => {
  try {
    const [homePage, siteSettings] = await Promise.all([
      client.fetchHomepage(),
      client.fetchSiteSettings(),
    ])
    return { props: { homePage, siteSettings }, revalidate: 10 }
  } catch (e) {
    Sentry.captureException(e, 'page_fetch_error', { ctx })
    return { props: {}, revalidate: 1 }
  }
}

export default Home
