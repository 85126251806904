import * as React from 'react'
import { ThemeProvider } from '@xstyled/styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { TrailersBlockWrapper, TextWrapper, TrailersWrapper } from './styles'
import { TrailersBlock as TrailersBlockType } from '../../types/sanity'
import { MuxVideo } from '../MuxVideo'
import { PortableText } from '@portabletext/react'

interface TrailersBlockProps {
  content: TrailersBlockType
}

export const TrailersBlock: React.FC<TrailersBlockProps> = ({ content }) => {
  if (!content) return null
  const { intro, trailers } = content
  return (
    <TrailersBlockWrapper>
      {intro && (
        <TextWrapper>
          <PortableText value={intro} />
        </TextWrapper>
      )}
      <TrailersWrapper>
        {trailers &&
          trailers.map((trailer, i) => {
            if (!trailer.video) return null
            return (
              <div key={i}>
                <MuxVideo video={trailer.video} />
                <div className="info">
                  <h2>{trailer.videoTitle}</h2>
                  {trailer.text && <PortableText value={trailer.text} />}
                </div>
              </div>
            )
          })}
      </TrailersWrapper>
    </TrailersBlockWrapper>
  )
}
