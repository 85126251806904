import * as React from 'react'
import Link from 'next/link'
import { ProductBlockWrapper } from './styles'
import { ProductLinkBlock } from '../../types/sanity'
import { Heading } from '../Text'
import { formatPrice } from '@/utils'

interface ProductBlockProps {
  content: ProductLinkBlock
}

export const ProductBlock: React.FC<ProductBlockProps> = ({ content }) => {
  const { product } = content
  if (!product) return null
  const imageUrl = product.store?.previewImageUrl
  if (!imageUrl) return null
  const title = product.store?.title
  const price = product.store?.priceRange?.minVariantPrice
  const linkTo = `/products/${product.store?.slug?.current}`
  return (
    <ProductBlockWrapper>
      <Link href={linkTo}>
        <img className="product_thumb" src={imageUrl} alt={title || ''} />
        <Heading level="h2" fontFamily="serif" fontWeight={100}>
          {title?.toUpperCase()}
          <br />
          {price ? formatPrice(price) : null}
        </Heading>
      </Link>
    </ProductBlockWrapper>
  )
}
