import * as React from 'react'
import { defaultTheme } from '@/theme/defaultTheme'
import { ThemeProvider } from '@xstyled/styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { Wrapper } from './styles'
import { VideoBlock as VideoBlockType } from '../../types/sanity'
import { MuxVideo } from '../MuxVideo'

interface VideoBlockProps {
  content: VideoBlockType
}

export const VideoBlock = ({ content }: VideoBlockProps) => {
  if (!content.video) return null
  return <MuxVideo video={content.video} />
}
