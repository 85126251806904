import * as React from 'react'
import { ImageTextBlock } from './ImageTextBlock'
import { MailingListBlock } from './MailingListBlock'
import { ProductBlock } from './ProductBlock'
import { VideoBlock } from './VideoBlock'
import { TrailersBlock } from './TrailersBlock'
import { ContentBlock as ContentBlockType } from '../../types/sanity'

interface ContentBlockProps {
  content: ContentBlockType
}

/**
 * determines the format of the content block and renders the appropriate component
 */

// eslint-disable-next-line react/display-name
export const ContentBlock: React.FC<ContentBlockProps> = ({ content }) => {
  switch (content._type) {
    // case 'ImageTextBlock':
    //   return <ImageTextBlock content={content} />
    // case 'MailingListBlock':
    //   return <MailingListBlock content={content} />
    case 'productLinkBlock':
      return <ProductBlock content={content} />
    case 'videoBlock':
      return <VideoBlock content={content} />
    case 'trailersBlock':
      return <TrailersBlock content={content} />
    default:
      // @ts-ignore
      console.warn(`No content block for type "${content._type}"`)
      return null
  }
}
