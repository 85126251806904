import styled, { css } from '@xstyled/styled-components'

export const Wrapper = styled.div`
  padding: 9 6;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;

  a {
    text-decoration: none;
  }
  .product_thumb {
    max-height: 80vh;
    height: auto;
    max-width: 100%;
    width: auto;
    object-fit: contain;
  }
`

export const ProductBlockWrapper = styled.div`
  padding: 9 6;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  a {
    text-decoration: none;
  }
  h2 {
    font-size: 5;
  }
  .product_thumb {
    max-height: 80vh;
    height: auto;
    max-width: 100%;
    width: auto;
    object-fit: contain;
  }
  @media screen and (max-width: 1024px) {
    padding: 4;
    h2 {
      font-size: 5;
    }
  }
`

export const TrailersBlockWrapper = styled.div`
  width: 100%;
  padding: 6 6 10 6;
  background-color: var(--six);
  @media screen and (max-width: 1024px) {
    padding: 2;
  }
`

export const TextWrapper = styled.div`
  color: black;
  padding: 9 6;
  margin: 0 auto;
  max-width: 960px;
  text-align: center;
  p {
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -1.5px;
  }
  @media screen and (max-width: 1024px) {
    padding: 4 6;
    max-width: 570px;
    p {
      font-size: 20px;
      line-height: 1.33;
      letter-spacing: -0.75px;
    }
  }
`

export const TrailersWrapper = styled.div`
  color: black;
  padding: 0;
  background-color: var(--three);
  .info {
    padding: 6;
    margin: 0 auto;
    max-width: 640px;
  }
  p {
    text-align: center;
    text-transform: uppercase;
  }
  h2 {
    margin: 0;
    text-align: center;
    font-size: 48px;
    letter-spacing: -1.5px;
    font-weight: 700;
    font-family: var(--sans);
    text-transform: uppercase;
  }
  @media screen and (max-width: 1024px) {
    .info {
      padding: 4;
      max-width: 360px;
    }
    h2 {
      margin: 0;
      font-size: 30px;
      letter-spacing: -0.75px;
    }
    p {
      font-size: 13px;
    }
  }
`
